import React, { useRef, useState } from "react";
import SwiperComponent from "../components/SwiperComponent";
import backgroundImage1 from "../images/project.png";
import backgroundImage2 from "../images/project2.png";
import backgroundImage3 from "../images/project3.png";
import backgroundImage4 from "../images/eshop.png"; // Ensure this path is correct
import backgroundImage5 from "../images/tooil_fitness.png"; // Ensure this path is correct
import { motion, useScroll, useTransform } from "framer-motion";

// Helper function to create project animations
const useProjectAnimation = (
  targetRef,
  opacityRange,
  scaleRange,
  translateXRange,
  translateYRange = [0, 0]
) => {
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end center"],
  });

  const opacity = useTransform(scrollYProgress, [0, 1], opacityRange);
  const scale = useTransform(scrollYProgress, [0, 1], scaleRange);
  const translateX = useTransform(scrollYProgress, [0, 1], translateXRange);
  const translateY = useTransform(scrollYProgress, [0, 1], translateYRange);

  return { opacity, scale, translateX, translateY };
};

export default function Projects() {
  const target1 = useRef(null);
  const target2 = useRef(null);
  const target3 = useRef(null);
  const target4 = useRef(null);
  const target5 = useRef(null);

  // State to manage expanded view
  const [showAllProjects, setShowAllProjects] = useState(false);

  // Animations for each project
  const project1Animations = useProjectAnimation(
    target1,
    [0.1, 1],
    [0.8, 1],
    ["0%", "0%"]
  );
  const project2Animations = useProjectAnimation(
    target2,
    [0, 1],
    [0.8, 1],
    ["0%", "0%"],
    ["0%", "0%"]
  );
  const project3Animations = useProjectAnimation(
    target3,
    [0, 1],
    [0.5, 1],
    ["0%", "0%"],
    ["0%", "0%"]
  );
  const project4Animations = useProjectAnimation(
    target4,
    [0, 1],
    [0.5, 1],
    ["0%", "0%"],
    ["0%", "0%"]
  );
  const project5Animations = useProjectAnimation(
    target5,
    [0, 1],
    [0.5, 1],
    ["0%", "0%"],
    ["0%", "0%"]
  );

  // Project data
  const projects = [
    {
      ref: target5,
      animations: project5Animations,
      backgroundImage: backgroundImage5,
      name: "TFC Gym: Fitness Website",
      description: "A modern gym website built with React and Tailwind CSS, featuring class schedules, membership plans, and trainer profiles for a seamless fitness experience.",
      link: "https://www.tooilfitnessclub.com.np/",
    },
    {
      ref: target4,
      animations: project4Animations,
      backgroundImage: backgroundImage4,
      name: "E-Shop: MERN Stack E-Commerce Platform",
      description:
        "A full-stack e-commerce platform with product order tracking, JWT authentication, and an admin dashboard. Built with MERN stack for seamless user experience and efficient management.",
      link: "https://eshop.poudelraj.com.np/",
    },

    {
      ref: target1,
      animations: project1Animations,
      backgroundImage: backgroundImage1,
      name: "React Quiz App",
      description:
        "This website was made with React. In this Project, UseState is mostly being used for updating state with user interaction.",
      link: "https://quiz-with-raj.vercel.app/",
    },
    {
      ref: target2,
      animations: project2Animations,
      backgroundImage: backgroundImage2,
      name: "Add to Cart",
      description:
        "This website was made with React which is based on Firebase Realtime database management system.",
      link: "https://add-to-cart.poudelraj.com.np/",
    },
    {
      ref: target3,
      animations: project3Animations,
      backgroundImage: backgroundImage3,
      name: "Expense Manager",
      description:
        "Expense Manager is a React-based web application for tracking daily expenses.",
      link: "https://room.poudelraj.com.np/",
    },
  ];

  // Number of projects to show initially
  const initialProjectsToShow = 3;
  const projectsToShow = showAllProjects
    ? projects
    : projects.slice(0, initialProjectsToShow);

  return (
    <div className="relative overflow-hidden">
      {/* Render visible projects */}
      {projectsToShow.map((project, index) => (
        <div key={index} ref={project.ref} className="relative h-screen">
          <motion.div
            className="w-full h-full flex items-center justify-center bg-cover bg-center rounded-lg"
            style={{
              opacity: project.animations.opacity,
              scale: project.animations.scale,
              x: project.animations.translateX,
              y: project.animations.translateY,
              backgroundImage: `linear-gradient(to bottom left, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6)), url(${project.backgroundImage})`,
              transformOrigin: "center center",
            }}
          >
            <SwiperComponent
              pName={project.name}
              pDescription={project.description}
              pLink={project.link}
            />
          </motion.div>
        </div>
      ))}

      {/* "See More" Button */}
      <div className="flex justify-center my-8">
        <button
          onClick={() => setShowAllProjects(!showAllProjects)}
          className="px-6 py-2 bg-white-400 text-slate-300 border-2 border-green-400 hover:bg-slate-950 hover:text-green-400 rounded-lg transition-colors"
        >
          {showAllProjects ? "See Less" : "See More"}
        </button>
      </div>
    </div>
  );
}
